.font-inter {
    font-family: 'Inter', sans-serif;
}

.font-IBM {
    font-family: 'IBM Plex Sans', sans-serif;
}   

.bg-webgrey {
    background-color: #EDF2F4;
}

.bg-webnavyblue {
    background-color: #2B2D42;
}

.text-webred {
    color: #D90429;
}

.text-webred2 {
    color: #EF233C;
}

.text-webnavyblue {
    color: #2B2D42;
}

.text-webblue {
    color: #8D99AE;
}

.text-webgrey {
    color: #9a9a9a;
}

.text-webslate {
    color: #454545;
}
